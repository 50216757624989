import React, { useEffect, useState } from "react"
import Title from "./Title"
import styled from "styled-components"
import base from "./Airtable"
// import { FaVoteYea } from "react-icons/fa"
import { CgCalendarNext } from "react-icons/cg"

const DailyLatin = () => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const getRecords = async () => {
    const lastRecords = await base("LatinQuotes").select().all()
    const maxRecord = lastRecords.length

    function getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }

    let randomId = getRndInteger(1, maxRecord)

    let filter = "({latin_quotes__row} = '" + randomId + "')"

    const dailyLatin = await base("LatinQuotes")
      .select({
        filterByFormula: filter,
      })
      .all()
      .catch(err => console.log(err))

    // console.log("dailyLatin", dailyLatin)
    // console.log("randomId", randomId)
    // const newItems = records.map(record => {
    //   const { id, fields } = record
    //   return { id, fields }
    // })
    // setItems(newItems)
    setItems(dailyLatin)
    setLoading(false)
  }

  // const giveVote = async id => {
  //   setLoading(true)
  //   const tempItems = [...items].map(item => {
  //     if (item.id === id) {
  //       let { id, fields } = item
  //       fields = { ...fields, votes: fields.votes + 1 }
  //       return { id, fields }
  //     } else {
  //       return item
  //     }
  //   })

  //   const records = await base("Survey")
  //     .update(tempItems)
  //     .catch(err => console.log(err))
  //   const newItems = records.map(record => {
  //     const { id, fields } = record
  //     return { id, fields }
  //   })
  //   setItems(newItems)
  //   setLoading(false)
  // }

  useEffect(() => {
    getRecords()
  }, [])

  return (
    <Wrapper className="section" id="index-dailylatin">
      <div className="section-center">
        <Title title="Napi latin"></Title>
        {/* <h3>most important room in the house?</h3> */}
        {loading ? (
          <h3>keresem...</h3>
        ) : (
          <div className="container">
            {items.map(item => {
              const {
                id,
                fields: {
                  latin_quotes__quote,
                  latin_quotes__magyarul,
                  latin_quotes__author,
                },
              } = item
              return (
                <article key={id}>
                  <div className="grid">
                    <div className="author">
                      <h4> {latin_quotes__author.toUpperCase()}</h4>
                    </div>
                    <div className="quote">
                      <h4>{latin_quotes__quote}</h4>
                      <h5>{latin_quotes__magyarul}</h5>
                      {/* <p>{author}</p> */}
                    </div>
                    <div className="nextQuote">
                      {" "}
                      <button onClick={() => getRecords()}>
                        <CgCalendarNext />
                      </button>
                    </div>
                  </div>
                </article>
              )
            })}
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background-color: var(--clr-light-3);
  .section-center {
    width: 90vw;
    max-width: var(--max-width);
    padding-top: 4rem;

    margin: 0 auto;
    h3 {
      text-align: center;
      color: var(--clr-primary-5);
      margin-bottom: 4rem;
    }
    .container {
      margin-top: 2rem;
      ${
        "" /* display: grid;
      gap: 2rem;
      grid-gap: 2rem;
      @media (min-width: 992px) {
        & {
          grid-template-columns: 1fr 1fr;
        }
      }
      @media (min-width: 1200px) {
        & {
          grid-template-columns: 1fr 1fr 1fr;
        }
      } */
      }
    }
    article {
      background: var(--clr-light-7);
      border-radius: var(--radius);
      padding: 0.75rem 1rem;
      .author {
        grid-area: auth;
        h4 {
          color: var(--clr-light-6);
          font-size: 1rem;
          background: var(--clr-primary-5);
          padding: 0.5rem 1rem;
          border-radius: var(--radius);
        }
      }

      .quote {
        grid-area: qt;
      }

      .nextQuote {
        grid-area: nextqt;
        text-align: right;
      }

      .grid {
        display: grid;
        ${"" /* grid-template-columns: auto 1fr auto; */}
        gap: 1.5rem 3rem;
        grid-gap: 1.5rem 3rem;
        align-items: center;
        grid-template-columns: repeat(6, 1fr);
        ${"" /* grid-template-rows: auto; */}
      }

      p {
        margin-bottom: 0;
        color: var(--clr-grey-5);
        letter-spacing: var(--spacing);
      }
      h4 {
        color: var(--clr-primary-5);
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        ${"" /* text-align: center; */}
        ${"" /* padding-left: 10%; */}
        text-align: center;
      }

      h5 {
        color: var(--clr-primary-5);
        text-align: center;
        padding-left: 20%;
      }
      button {
        ${"" /* background: transparent; */}
        background: var(--clr-primary-5);
        font-size: 2rem;
        cursor: pointer;
        color: var(--clr-light-6);
        border: 2px solid var(--clr-primary-5);
        padding: 5px 10px;
        border-radius: var(--radius);
      }
      button:hover {
        color: var(--clr-red-5);
        transform: scale(0.96);
      }
    }
  }

  @media (max-width: 768px) {
    .grid {
      grid-template-areas:
        "qt qt qt qt qt qt"
        " auth auth auth auth nextqt nextqt";
    }
  }

  @media (min-width: 768px) {
    .grid {
      grid-template-areas: "auth qt qt qt qt nextqt";
    }
  }
  @media (min-width: 992px) {
    .grid {
      grid-template-areas: "auth qt qt qt qt nextqt";
    }
  }
  @media (min-width: 1200px) {
    .grid {
      grid-template-areas: "auth qt qt qt qt nextqt";
    }
  }
`
export default DailyLatin
