import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
import Image from "gatsby-image"
import styled from "styled-components"

const slugifyPost = require("../../slugifyPost")

const Collections = ({ collections: data, title, page }) => {
  const [collections, setPoems] = React.useState(data)
  // more logic with setPoems-el....
  // console.log(poems)

  // console.log("Poemsjs  data", data)
  // console.log("Poems.js pagecontext", pageContext)

  return (
    <Wrapper className="section">
      <Title title={title || "Komplett művek"} />

      <div className="section-center">
        {collections.map(item => {
          const { id } = item
          const {
            collections_title,
            collections_excerpt,
            collections_author,
            collections_row,
          } = item.data
          const fluid =
            item.data.collections_image.localFiles[0].childImageSharp.fluid

          return (
            <article key={id} className="card">
              <Link
                // to={`/leftover/poems/${slugifyPost(poems_title)}`}
                to={`/library/collection/${collections_row}-${slugifyPost(
                  collections_title
                )}`}
                className="slug"
              >
                <Image fluid={fluid} className="img" />
                <h3>{collections_title}</h3>
                <p>{collections_author}</p>
                <div className="info">
                  <p
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: collections_excerpt,
                    }}
                  ></p>
                </div>
              </Link>
            </article>
          )
        })}
      </div>

      {/*  csak akkor látható a button, ha az index-en vagyunk az összes versek oldalon nem */}
      {!page && (
        <Link to="/library/collections/1" className="btn">
          Összes gyűjtemény
        </Link>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-light-3);

  .section-center {
    margin-top: 4rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;
    .img {
      ${"" /* height: 20rem; */}
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article {
      box-shadow: var(--light-shadow);
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article:hover {
      box-shadow: var(--dark-shadow);
    }
    .card {
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      background: var(--clr-primary-5);
      &:hover .img {
        opacity: 0.2;
      }
      .info {
        padding: 0 0.5rem;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        opacity: 0;
        transition: var(--transition);
        text-align: center;
        p {
          margin-bottom: 0.5rem;
          ${"" /* color: var(--clr-light-5); */}
          ${"" /* text-transform: uppercase; */}
        }
      }
      &:hover .info {
        opacity: 1;
      }

      h3 {
        text-decoration: none;
        padding-top: 1rem;
      }

      p {
        color: var(--clr-light-5);
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  a {
    display: block;
    width: 9rem;
    text-align: center;
    margin: 0 auto;
    ${"" /* margin-top: 3rem; */}
    color: var(--clr-light-3);
  }

  .btn {
    color: var(--clr-primary-22);
  }

  .btn:hover {
    color: var(--clr-primary-1);
  }
  .slug {
    width: auto;
  }
`
export default Collections
