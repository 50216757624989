import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
import styled from "styled-components"

const LatinQuotes = ({ latinquotes: data, title, page }) => {
  const [latinquotes, setQuotes] = React.useState(data)
  // more logic with setPoems-el....
  return (
    <Wrapper className="section">
      <div className="section-center">
        <div className="container">
          <Title title={title || "Latin idézetek"} />

          <ul>
            {latinquotes.map(item => {
              const { id } = item
              const {
                latin_quotes__quote,
                latin_quotes__author,
                latin_quotes__magyarul,
              } = item.data
              return (
                <li key={id}>
                  <div>
                    <h4
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: latin_quotes__quote.childMarkdownRemark.html,
                      }}
                    ></h4>
                    <h5
                      dangerouslySetInnerHTML={{
                        __html: latin_quotes__magyarul.childMarkdownRemark.html,
                      }}
                    ></h5>
                  </div>

                  <div className="author">
                    {latin_quotes__author.toUpperCase()}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {/*  csak akkor látható a button, ha az index-en vagyunk az összes versek oldalon nem */}
      {!page && (
        <Link to="/leftover/latinquotes/1" className="btn">
          Összes latin idézet
        </Link>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background-color: var(--clr-light-3);
  .container {
    width: 90vw;
    max-width: var(--max-width);

    margin: 0 auto;
    h3 {
      text-align: center;
      color: var(--clr-primary-5);
      margin-bottom: 4rem;
    }
    ul {
      margin-top: 2rem;
      display: grid;
      gap: 2rem;
      grid-gap: 2rem;
      @media (min-width: 992px) {
        & {
          ${"" /* grid-template-columns: 1fr 1fr; */}
        }
      }
      @media (min-width: 1200px) {
        & {
          ${"" /* grid-template-columns: 1fr 1fr 1fr; */}
        }
      }
    }
    li {
      background: var(--clr-light-7);
      border-radius: var(--radius);
      padding: 0.75rem 1rem;
      display: grid;
      grid-template-columns: 6fr 2fr;
      gap: 0 3rem;
      grid-gap: 0 3rem;
      align-items: center;
      .author {
        color: var(--clr-light-6);
        ${"" /* font-size: 1.5rem; */}
        background: var(--clr-primary-5);
        padding: 0.5rem 1rem;
        border-radius: var(--radius);
        text-align: center;
      }
      p {
        margin-bottom: 0;
        color: var(--clr-primary-5);
        letter-spacing: var(--spacing);
      }
      h4 {
        color: var(--clr-primary-5);
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
      }

      h5 {
        color: var(--clr-primary-5);
        text-align: right;
        font-weigth: bold;
      }
      button {
        ${"" /* background: transparent; */}
        background: var(--clr-primary-5);
        font-size: 2rem;
        cursor: pointer;
        color: var(--clr-light-6);
        border: 2px solid var(--clr-primary-5);
        padding: 5px 10px;
        border-radius: var(--radius);
      }
      button:hover {
        color: var(--clr-red-5);
        transform: scale(0.96);
      }
    }
  }
  @media (min-width: 768px) {
    .author {
      font-size: 1rem;
    }
  }
  @media (min-width: 992px) {
    .author {
      font-size: 1.25rem;
    }
  }
  @media (min-width: 1200px) {
    .author {
      font-size: 1.5rem;
    }
  }
`

export default LatinQuotes
