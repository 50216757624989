import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import slugifyPost from "../../slugifyPost"

const PaginationSimple = ({
  oneItemPage,
  allItemPage,
  prevTitle,
  nextTitle,
  prevRow,
  nextRow,
  pageContext,
}) => {
  const { previous, next } = pageContext

  return (
    <Wrapper className="section">
      <div className="section-center">
        <nav className="">
          <ul className="pagination">
            <li className="page-item">
              {previous && (
                <Link
                  // to={`/leftover/poem/${row}-${slugifyPost(
                  //   previous.data.poems_title
                  // )}`}

                  to={`${oneItemPage}${prevRow}-${slugifyPost(prevTitle)}`}
                  rel="next"
                  className="page-link"
                >
                  {/* <h4>{"<< " + previous.data.poems_title}</h4> */}
                  <h4>{"<< " + `${prevTitle}`}</h4>
                </Link>
              )}
            </li>

            <li className="page-item">
              <Link to={allItemPage} className="page-link">
                <h4>Összes</h4>
              </Link>
            </li>

            <li className="page-item">
              {next && (
                <Link
                  to={`${oneItemPage}${nextRow}-${slugifyPost(nextTitle)}`}
                  rel="prev"
                  className="page-link"
                >
                  <h4>{`${nextTitle}` + " >>"}</h4>
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-light-10);
  padding: 2rem 2 rem;

  .section-center {
    nav {
      display: flex;
      justify-content: center;
      background: transparent;
      align-items: center;

      .pagination {
        display: flex;
      }
      .pagination a {
        background: var(--clr-primary-5);
        color: var(--clr-primary-22);
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        border: 1px solid #ddd;
      }

      .pagination a.active {
        background: var(--clr-primary-20);
        color: var(--clr-primary-1);
        border: 1px solid var(--clr-primary-10);
      }

      .pagination a:hover:not(.active) {
        background: var(--clr-primary-20);
        color: var(--clr-primary-1);
      }

      .pagination a:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      .pagination a:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      h4 {
        padding: 0.5rem 1rem;
        margin: 0;
      }
    }
  }
`

export default PaginationSimple
