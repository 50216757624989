import React from "react"
// import AudioPlayer from "react-h5-audio-player"
// import "react-h5-audio-player/lib/styles.css"
import styled from "styled-components"
// import 'react-h5-audio-player/lib/styles.less' Use LESS
// import 'react-h5-audio-player/src/styles.scss' Use SASS

const Audio = ({ audioSrcUrl, audioTitle, ...props }) => (
  <Wrapper className="section">
    {/* <AudioPlayer
      // autoPlay
      src={audioSrcUrl}
      onPlay={e => console.log("onPlay")}
      // other props here
    /> */}
    <div className="audio">
      <h3>{audioTitle} </h3>
      <iframe
        className="player"
        frameBorder="0"
        allowtransparency="true"
        width="100%"
        height="100%"
        title={audioTitle}
        src={audioSrcUrl}
      ></iframe>
    </div>
  </Wrapper>
)

export default Audio

const Wrapper = styled.section`
  .audio {
    ${"" /* background: red; */}
  }

  .player {
    display: block;
    margin: 0 auto;
  }
`
