import React from "react"
import BackgroundImage from "gatsby-background-image"
import styled, { keyframes } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    file(relativePath: { eq: "hero_1920.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Background = ({ children, image }) => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  // console.log(fluid)

  return (
    <Wrapper>
      <BackgroundImage
        Tag="div"
        fluid={image || fluid}
        className="background-image colortobw"
        preserveStackingContext={true}
        style={{
          // backgroundSize: `center`,
          backgroundPosition: `right bottom`,
        }}
      >
        {children}
      </BackgroundImage>
    </Wrapper>
  )
}

const fadeIn = keyframes`
      from{
         background-color:rgba(0,0,0,0.8);
      }
      to{
        background-color:rgba(0,0,0,0.4);
      }
      `

const Wrapper = styled.section`
  .background-image {
    /* MUST!!!!!! */
    min-height: 100vh;
    margin-top: 0rem;
    display: grid;
    place-items: center;
    animation: ${fadeIn} 2s ease-in-out 1 forwards;
  }
  .background-image::before {
    opacity: 1;
  }

  .colortobw {
    -webkit-transition: -webkit-filter 700ms ease-in-out;
    -webkit-filter: grayscale(100%) !important;
    transiton: filter 700ms ease-in-out;
    filter: grayscale(100%) !important;
  }
  .colortobw:hover {
    -webkit-transition: -webkit-filter 700ms ease-in-out;
    -webkit-filter: grayscale(0) !important;
    transiton: filter 700ms ease-in-out;
    filter: grayscale(0) !important;
  }
`
export default Background
