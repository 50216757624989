import React from "react"
// import { Link } from "gatsby"
import Title from "./Title"
import styled from "styled-components"
import Image from "gatsby-image"

const Quotes = ({ quotes: data, title, page }) => {
  const [quotes, setQuotes] = React.useState(data)
  // more logic with setPoems-el....
  return (
    <Wrapper className="section">
      <Title title={title || "Idézetek"} />

      <div className="section-center">
        {quotes.map(item => {
          const { id } = item
          const {
            quotes_text,
            quotes_author,
            quotes_source,
            quotes_image,
          } = item.data

          const authorImg = quotes_image.localFiles[0].childImageSharp.fixed

          return (
            <article key={id}>
              <div className="container">
                <span
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: quotes_text.childMarkdownRemark.html,
                  }}
                ></span>

                <h4>{quotes_author}</h4>
                <h5>{quotes_source}</h5>
              </div>
              <div className="image">
                <Image fixed={authorImg} className="img" alt="author"></Image>
              </div>
            </article>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-light-7);
  .section-center {
    margin-top: 4rem;
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;
    padding-bottom: 2rem;
    max-width: 960px;

    article {
      padding: 2rem;
      background-color: var(--clr-light-3);

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        float: right;
      }

      .container {
        float: left;
      }

      .image {
        float: right;
      }
      h4 {
        text-transform: uppercase;
        font-weight: bold;
        padding-top: 2rem;
        color: var(--clr-primary-5);
      }

      h5 {
        color: var(--clr-primary-5);
        text-align: right;
        padding-bottom: 1rem;
      }
      p {
        color: var(--clr-grey-3);
        max-width: 35em;
        text-align: left;
      }
    }
    @media (min-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media (min-width: 992px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media (min-width: 1200px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`

export default Quotes
