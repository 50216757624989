import React from "react"
import styled from "styled-components"
const Footer = () => {
  return (
    <Wrapper className="section">
      <footer className="section-center">
        <p>Maradék &copy; {new Date().getFullYear()} PixelJuice</p>
        <a
          href="https://icons8.com/"
          target="_blank"
          rel="noreferrer"
          className="small"
        >
          Icons by Icons8
        </a>
      </footer>
    </Wrapper>
  )
}
const Wrapper = styled.footer`
  height: 5rem;
  display: grid;
  place-items: center;
  background: var(--clr-grey-2);
  text-align: center;
  padding: 1rem;
  p {
    color: var(--clr-light-5);
    margin-bottom: 0;
    @media (max-width: 576px) {
      font-size: 0.75rem;
    }
  }
  a {
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--clr-light-3);
    @media (max-width: 576px) {
      font-size: 0.6rem;
    }
  }

  a:hover {
    color: var(--clr-light-10);
  }
`
export default Footer
