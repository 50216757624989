import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
import Image from "gatsby-image"
import styled from "styled-components"

const slugifyPost = require("../../slugifyPost")

const Movies = ({ movies: data, title, page }) => {
  const [movies, setPoems] = React.useState(data)
  // more logic with setPoems-el....
  // console.log(poems)

  // console.log("Poemsjs  data", data)
  // console.log("Poems.js pagecontext", pageContext)

  return (
    <Wrapper className="section">
      <Title title={title || "1001 Film"} />

      <div className="section-center">
        {movies.map(item => {
          const { id } = item
          const {
            movies_title,
            movies_director,
            movies_title__hungarian,
            movies_row,
            movies_excerpt,
          } = item.data
          const fluid =
            item.data.movies_image.localFiles[0].childImageSharp.fluid

          return (
            <article key={id} className="card">
              <Link
                // to={`/leftover/poems/${slugifyPost(poems_title)}`}
                to={`/island/movie/${movies_row}-${slugifyPost(movies_title)}`}
                className="slug"
              >
                <Image fluid={fluid} className="img" />

                <h3> {movies_title__hungarian} </h3>
                <p>{movies_director}</p>

                <div className="info">
                  {movies_excerpt.childMarkdownRemark.rawMarkdownBody}
                </div>
              </Link>
            </article>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-light-3);

  .section-center {
    margin-top: 4rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;
    .img {
      ${"" /* height: 20rem; */}
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article {
      box-shadow: var(--light-shadow);
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article:hover {
      box-shadow: var(--dark-shadow);
    }
    .card {
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      background: var(--clr-primary-5);
      &:hover .img {
        opacity: 0.2;
      }

      .info {
        padding: 1rem 0.5rem 0.5rem 0.5rem;
        background-color: rgba(42, 25, 25, 0.85);
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        opacity: 0;
        transition: var(--transition);
        text-align: center;
        p {
          margin-bottom: 0.5rem;
          ${"" /* color: var(--clr-light-5); */}
          ${"" /* text-transform: uppercase; */}
        }
      }
      &:hover .info {
        opacity: 1;
      }

      h3 {
        text-decoration: none;
        padding-top: 1rem;
      }

      p {
        color: var(--clr-light-5);
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  a {
    display: block;
    width: 9rem;
    text-align: center;
    margin: 0 auto;
    ${"" /* margin-top: 3rem; */}
    color: var(--clr-light-3);
  }

  .btn {
    color: var(--clr-primary-22);
  }

  .btn:hover {
    color: var(--clr-primary-1);
  }
  .slug {
    width: auto;
  }
`
export default Movies
