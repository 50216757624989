import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Title from "./Title"
// import styled, { createGlobalStyle } from "styled-components"
import styled from "styled-components"
import Image from "gatsby-image"
import { FaQuoteRight } from "react-icons/fa"
import { FiChevronRight, FiChevronLeft } from "react-icons/fi"

const query = graphql`
  {
    allAirtable(
      filter: { table: { eq: "Quotes" } }
      sort: { fields: recordId, order: DESC }
    ) {
      nodes {
        id
        recordId
        data {
          quotes_author
          quotes_source
          quotes_row
          quotes_text {
            childMarkdownRemark {
              html
            }
          }
          quotes_image {
            localFiles {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`

const SliderQuotes = () => {
  const {
    allAirtable: { nodes: quotes },
  } = useStaticQuery(query)

  const [index, setIndex] = React.useState(0)
  React.useEffect(() => {
    const lastIndex = quotes.length - 1
    if (index < 0) {
      setIndex(lastIndex)
    }
    if (index > lastIndex) {
      setIndex(0)
    }
  }, [index, quotes])

  return (
    <Wrapper className="section">
      <Title title="Idézetek" />
      <div className="section-center">
        {quotes.map((quote, quoteIndex) => {
          const {
            data: { quotes_image, quotes_text, quotes_source, quotes_author },
          } = quote

          {
            /* console.log("auth image", image) */
          }

          const authorImg = quotes_image.localFiles[0].childImageSharp.fixed

          let position = "nextSlide"

          if (quoteIndex === index) {
            position = "activeSlide"
          }
          if (
            quoteIndex === index - 1 ||
            (index === 0 && quoteIndex === quotes.length - 1)
          ) {
            position = "lastSlide"
          }

          return (
            <article className={position} key={quoteIndex}>
              {/* <p className="text">{text}</p> */}

              <Image fixed={authorImg} className="img" alt="author"></Image>
              <h4>{quotes_author}</h4>
              <p className="title">{quotes_source}</p>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: quotes_text.childMarkdownRemark.html,
                }}
              ></div>
              <FaQuoteRight className="icon"></FaQuoteRight>
            </article>
          )
        })}
        <button className="prev" onClick={() => setIndex(index - 1)}>
          <FiChevronLeft />
        </button>
        <button className="next" onClick={() => setIndex(index + 1)}>
          <FiChevronRight />
        </button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: var(--clr-light-7);
  .section-center {
    margin-top: 4rem;
    width: 80vw;
    height: 450px;
    max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
    .img {
      border-radius: 50%;
      margin-bottom: 1rem;
      height: 150px;
      width: 150px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    h4 {
      text-transform: uppercase;
      color: var(--clr-primary-5);
      margin-bottom: 0.25rem;
    }
    .title {
      text-transform: capitalize;
      margin-bottom: 0.75rem;
    }
    .text {
      max-width: 45em;
      margin: 0 auto;
      line-height: 2;
      color: var(--clr-grey-5);
      font-weight: bold;
    }
    .icon {
      font-size: 3rem;
      margin-top: 1rem;
      color: var(--clr-primary-5);
    }
    .prev,
    .next {
      position: absolute;
      top: 200px;
      transform: translateY(-50%);
      background: var(--clr-primary-5);
      color: var(--clr-primary-22);
      width: 1.25rem;
      height: 1.25rem;
      display: grid;
      place-items: center;
      border-color: var(--clr-primary-5);
      font-size: 1rem;
      border-radius: var(--radius);
      cursor: pointer;
      transition: var(--transition);
    }
    .prev:hover,
    .next:hover {
      background: var(--clr-primary-20);
      color: var(--clr-primary-1);
      border: 1px solid var(--clr-primary-10);
    }
    .prev {
      left: 0;
    }
    .next {
      right: 0;
    }
    @media (min-width: 800px) {
      .prev,
      .next {
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
      }
    }
    article {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: var(--transition);
    }
    article.activeSlide {
      opacity: 1;
      transform: translateX(0);
    }
    article.lastSlide {
      transform: translateX(-100%);
    }
    article.nextSlide {
      transform: translateX(100%);
    }

    a {
      display: block;
      width: 9rem;
      text-align: center;
      margin: 0 auto;
      margin-top: 3rem;
      color: var(--clr-light-3);
    }
  }
`
export default SliderQuotes
