import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
import Image from "gatsby-image"
import styled from "styled-components"

const slugifyPost = require("../../slugifyPost")

const Poems = ({ poems: data, title, page }) => {
  const [poems, setPoems] = React.useState(data)
  // more logic with setPoems-el....
  // console.log(poems)

  // console.log("Poemsjs  data", data)
  // console.log("Poems.js pagecontext", pageContext)

  return (
    <Wrapper className="section">
      <Title title={title || "Versek"} />

      <div className="section-center">
        {poems.map(item => {
          const { id } = item
          const {
            poems_title,
            poems_excerpt,
            poems_author,
            poems_row,
          } = item.data
          const fluid =
            item.data.poems_image.localFiles[0].childImageSharp.fluid

          return (
            <article key={id}>
              <div className="container">
                <Link
                  // to={`/leftover/poems/${slugifyPost(poems_title)}`}
                  to={`/leftover/poem/${poems_row}-${slugifyPost(poems_title)}`}
                  className="slug"
                >
                  <h3>{poems_title}</h3>
                  <p>{poems_author}</p>
                  <Image fluid={fluid} className="img" />
                  <div className="info">
                    <p
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: poems_excerpt.childMarkdownRemark.html,
                      }}
                    ></p>
                  </div>
                </Link>
              </div>
            </article>
          )
        })}
      </div>

      {/*  csak akkor látható a button, ha az index-en vagyunk az összes versek oldalon nem */}
      {!page && (
        <Link to="/leftover/poems/1" className="btn">
          Összes vers
        </Link>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-light-3);

  .section-center {
    margin-top: 4rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;
    .img {
      height: 20rem;
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article {
      box-shadow: var(--light-shadow);
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article:hover {
      box-shadow: var(--dark-shadow);
    }
    .container {
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      background: var(--clr-primary-5);
      &:hover .img {
        opacity: 0.2;
      }
      .info {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        opacity: 0;
        transition: var(--transition);
        text-align: center;
        p {
          margin-bottom: 0.5rem;
          color: var(--clr-light-5);
          text-transform: uppercase;
        }
      }
      &:hover .info {
        opacity: 1;
      }

      h3 {
        text-decoration: none;
      }

      p {
        color: var(--clr-grey-10);
      }
    }
    @media (min-width: 768px) {
      .img {
        height: 15rem;
      }
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 992px) {
      .img {
        height: 12.5rem;
      }
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1200px) {
      .img {
        height: 15rem;
      }
    }
  }

  a {
    display: block;
    width: 9rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 3rem;
    color: var(--clr-light-3);
  }

  .btn {
    color: var(--clr-primary-22);
  }

  .btn:hover {
    color: var(--clr-primary-1);
  }
  .slug {
    width: auto;
  }
`
export default Poems
