import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
import Image from "gatsby-image"
import styled from "styled-components"

const slugifyPost = require("../../slugifyPost")

const Excerpts = ({ excerpts: data, title, page }) => {
  const [excerpts, setPoems] = React.useState(data)
  // more logic with setPoems-el....
  return (
    <Wrapper className="section">
      <Title title={title || "Egypercesek"} />

      <div className="section-center">
        {excerpts.map(item => {
          const { id } = item
          const {
            excerpts_title,
            excerpts_excerpt,
            excerpts_author,
            excerpts_source,
            excerpts_row,
          } = item.data

          const icon =
            item.data.excerpts_icon.localFiles[0].childImageSharp.fixed

          return (
            <article key={id}>
              <div className="container">
                <Link
                  to={`/leftover/excerpt/${excerpts_row}-${slugifyPost(
                    excerpts_title
                  )}`}
                  className="slug"
                >
                  <Image fixed={icon}></Image>
                  <h4>{excerpts_title}</h4>

                  <h5>{excerpts_author}</h5>
                  {/* <h5>{excerpts_source}</h5> */}
                  <span
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: excerpts_excerpt,
                    }}
                  ></span>
                </Link>
              </div>
            </article>
          )
        })}
      </div>
      {/*  csak akkor látható a button, ha az index-en vagyunk az összes versek oldalon nem */}
      {!page && (
        <Link to="/leftover/excerpts/1" className="btn">
          Összes egyperces
        </Link>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-light-7);
  .section-center {
    margin-top: 4rem;
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;
    padding-bottom: 2rem;

    .icon {
      font-size: 4rem;
      color: var(--clr-primary-5);
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-top: 1rem;
    }

    article {
      padding: 2rem;
      background-color: var(--clr-light-3);
    }

    img {
      height: 64px;
      width: 64px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    h4 {
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 2rem;
      color: var(--clr-primary-5);
    }

    h5 {
      color: var(--clr-primary-5);
      text-align: right;
      padding-bottom: 1rem;
    }
    p {
      color: var(--clr-grey-3);
      max-width: 35em;
      text-align: left;
    }

    .text {
      color: var(--clr-primary-5);
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  a {
    display: block;
    width: 11rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 2rem;
  }

  .btn {
    width: 200px;
  }

  .slug {
    width: auto;
  }
`

export default Excerpts
