import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
import Image from "gatsby-image"
import styled from "styled-components"

const slugifyPost = require("../../slugifyPost")

const Albums = ({ albums: data, title, page }) => {
  const [albums, setPoems] = React.useState(data)
  // more logic with setPoems-el....
  // console.log(poems)

  // console.log("Poemsjs  data", data)
  // console.log("Poems.js pagecontext", pageContext)

  return (
    <Wrapper className="section">
      <Title title={title || "1001 Lemez"} />

      <div className="section-center">
        {albums.map(item => {
          const { id } = item
          const {
            albums_title,
            albums_excerpt,
            albums_performer,
            albums_row,
          } = item.data
          const fluid =
            item.data.albums_image.localFiles[0].childImageSharp.fluid

          return (
            <article key={id}>
              <div className="container">
                <Image fluid={fluid} className="img"></Image>
                <Link
                  // to={`/leftover/poems/${slugifyPost(poems_title)}`}
                  to={`/island/album/${albums_row}-${slugifyPost(
                    albums_title
                  )}`}
                  className="slug"
                >
                  <div className="info">
                    {/* <p
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: albums_excerpt,
                        }}
                      ></p> */}

                    <h3>{albums_title}</h3>
                    <p>{albums_performer}</p>
                  </div>
                </Link>
              </div>
            </article>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-light-3);

  .section-center {
    margin-top: 4rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;
    .img {
      ${"" /* height: 20rem; */}
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article {
      box-shadow: var(--light-shadow);
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article:hover {
      box-shadow: var(--dark-shadow);
    }
    .container {
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      background: var(--clr-primary-5);
      &:hover .img {
        opacity: 0.5;
      }

      a {
        display: block;
        width: 9rem;
        text-align: center;
        margin: 0 auto;
        ${"" /* margin-top: 3rem; */}
        color: var(--clr-light-3);
      }

      .info {
        padding: 1rem 0.5rem 0.5rem 0.5rem;
        ${"" /* background: var(--clr-primary-5); */}
        background-color: rgba(42, 25, 25, 0.85);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        opacity: 0;
        transition: var(--transition);
        text-align: center;
        p {
          margin-bottom: 0.5rem;
          color: var(--clr-light-3);
          text-transform: uppercase;
        }

        h3 {
          text-decoration: none;
        }
      }
      &:hover .info {
        opacity: 1;
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  .btn {
    color: var(--clr-primary-22);
  }

  .btn:hover {
    color: var(--clr-primary-1);
  }
  .slug {
    width: auto;
  }
`
export default Albums
